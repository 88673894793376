$govuk-assets-path: "~govuk-frontend/govuk/assets/";
@import "~govuk-frontend/govuk/all";

body {
  margin: 0px;
  font-family: Arial, "Helvetica", sans-serif;
}

.govuk-header__container {
  position: relative;
  margin-bottom: 0px;
  padding-top: 10px;
  border-bottom: 0px;
}

.govuk-header {
  position: relative;
  color: #000000;
  overflow-x: hidden;
  margin-bottom: 0px;
  height: auto;
  background: #f1f1f1;
  border-bottom: 3px solid #f0932e;
}

.sohas-header-title {
  width: auto;
  font-size: 18px;
  line-height: 24px;
  white-space: normal;
  color: #000000;
  font-weight: 700;
  vertical-align: middle;
}

.sohas-header-title a {
  text-decoration: none;
  color: #000000;
}

.header-logout {
  text-decoration: underline;
  vertical-align: middle;
  line-height: 24px;
}

.home-right H1 {
  font-weight: normal;
}

@media screen and (min-width: 642px) {
  .header-logo {
    text-align: right;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0px;
  }

  .sohas-header-title {
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: 0px;
    text-align: left;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 0px;
  }

  .header-logout {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 641px) {
  .header-logo {
    display: block;
    text-align: center;
    padding: 10px 0px;
  }

  .sohas-header-title {
    margin: 0 auto;
    text-align: center;
  }

  .header-logout {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
}
